import React from 'react'
import Layout from '../components/layout'
import { TeamViewer } from '../components/teamviewer'
import { PageTitle } from './../components/pagetitle'
import { Container } from 'react-bootstrap'
import SEO from '../components/seo'

const MySite = () => (
  <Layout>
    <SEO title="Pagina non trovata" />
    <main id="page-404">
      <Container>
        <PageTitle>
          Pagina <span className="accent">non trovata</span>
        </PageTitle>
        <p>La pagina che hai cercato di aprire non esiste.</p>
        <p>
          Hai bisogno di supporto? Avvia ora la sessione di TeamViewer con il
          seguente pulsante
        </p>
        <TeamViewer />
      </Container>
    </main>
  </Layout>
)

export default MySite
